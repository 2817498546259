@use "styles/breakpoints";
@use "styles/colours";
@use "styles/typography";

.registration {
  &__social {
    @media (min-width: breakpoints.$md) {
      min-width: 576px;
    }
  }
  
  &__tcView {
    flex-direction: row;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
    align-items: center;
    display:flex;
  }

  &__tcView2 {
    flex-direction: row;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 24px;
    align-items: center;
    display:flex;
    padding-bottom: 16px;
  }

  &__tcLabel {
    @extend .body;
    margin-left: 16px;
    margin-right: 16px;
    flex-direction: row;
    display:flex;
  }

  &__tcUnderLine {
    @extend .body-bold;
    text-decoration-line: underline;
    margin-left: 6px;
    margin-right: 6px;
  }

  &__tcError {
    @extend .body-bold;
    margin-bottom: 12px;
    color: colours.$red;
    margin-left: 64px;
  }

  &__tcInvisisable {
    @extend .body-bold;
    margin-bottom: 12px;
    opacity: 0;
    margin-left: 64px;
  }

  &__errorContainer {
    padding-bottom: 16px;
  }
}
